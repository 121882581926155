/*:root {*/
/*  --light-col-main: #fbf7f2;*/
/*  --dark-col-main: #272727;*/
/*  !*--pg1-bg-color: var(--light-col-main);*!*/
/*  --pg1-bg-color: var(--dark-col-main);*/
/*  --pg1-font-color: var(--light-col-main);*/
/*  !*--pg1-font-color: var(--dark-col-main);*!*/
/*}*/

html {
  scroll-snap-type: y mandatory;
  height: 100%;
  overflow-x: hidden;
}

/*ul {*/
/*  padding-left: 0;*/
/*}*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  margin: 0;
  color: var(--pg1-font-color);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
