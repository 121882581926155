.App-projects-portfolios-surround {
    width: 85%;
    margin-top: 2.5vh;
    border-radius: 5px;
    margin-bottom: 5vh;
}
.App-projects-portfolios {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
}
@media screen and (min-width: 0px) {
    .Project-card {
        width: 100%;
        height: 70vh;
        /*border-color: #242124;*/
        border-color: #fffefc;
        border-style: solid;
        border-width: 2px;
        border-radius: 5px;
        margin-bottom: 5vh;
        overflow: hidden;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}
@media screen and (min-width: 1200px) {
    .Project-card {
        width: 48%;
        height: 70vh;
        /*border-color: #242124;*/
        border-color: #fffefc;
        border-style: solid;
        border-width: 2px;
        border-radius: 5px;
        margin-bottom: 5vh;
        overflow: hidden;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}

.Project-avatar {
    width: 100%;
    height: 70%;
    /*transform: scale(0.99,1);*/
    background-size: 100%;
    padding: 6px;
    object-position: 0% 0%;
    object-fit: cover;
    /*border-style: solid;*/
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #272727;
}
.Project-title{
    padding-left: 10px;
    color: #272727;
}
.Project-desc{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    color: #272727;
    font-size: medium;
}
