@media screen and (min-width: 100px) {
    .App-cv-body {
        border-style: double;
        border-width: 5px;
        border-radius: 7.5px;
        width: 800px;
        /*width: 70vw;*/
        max-width: 90vw;
        padding: 5px;
        margin-bottom: 5vh;
        border-color: var(--pg1-font-color);
        background: var(--pg1-bg-color);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}

@media screen and (min-width: 1200px) {
    .App-cv-body {
        border-style: double;
        border-width: 5px;
        border-radius: 7.5px;
        width: 2000px;
        max-width: 50vw;
        padding: 5px;
        margin-bottom: 5vh;
        border-color: var(--pg1-font-color);
        background: var(--pg1-bg-color);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}

.Title{
    font-size: 20px;
}

.App-cv-contact{
    font-size: 17px;
    margin-left: 40px;
    margin-top: -15px;
}

.App-cv-subject-date{
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-right: 30px;
    margin-top: -48px;
}

.App-cv-content{
    /*display: flex;*/
    /*justify-content: start;*/
    font-size: 18px;
    margin-left: 35px;
    padding-right: 50px;
}

.Subject{
    font-size: 18px;
}
