:root {
  /*original white*/
  /*--light-col-main: #fbf7f2;*/
  /*rice*/
  /*--light-col-main: #faf5ef;*/
  /*porcelain*/
  --light-col-main: #fffefc;

  /*--dark-col-main: #272727;*/
  --dark-col-main: #242124;
  --icon_sz: 5vh;
  --icon-space-between: 0.4;
  /*--pg1-bg-color: var(--light-col-main);*/
  --pg1-bg-color: var(--dark-col-main);
  --pg1-font-color: var(--light-col-main);
  /*--pg1-font-color: var(--dark-col-main);*/
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #242124;
  border-radius: 50px;
}

.App-header{
  background-color: var(--pg1-bg-color);
  /*border-bottom: var(--pg1-font-color);*/
  opacity: 0.9;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--pg1-font-color);
  /*color: black;*/
}

.App-header-light{
  background-color: var(--pg1-font-color);
  opacity: 0.9;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--pg1-bg-color);
}

.App {
  text-align: center;
  margin: 0;
  height: 90%;
  overflow: hidden;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  from { border-color: transparent }
  to { border-color: var(--pg1-font-color); }
}

.App-typing {
  overflow: hidden;
  border-right: .1em solid;
  white-space: nowrap;
  font-size: 1.2rem;
  height: 50%;
  width: 50%;
  animation:
          typing 4s steps(50, end) forwards,
          blink .8s infinite;
}

.App-typing-error  {
  overflow: hidden;
  border-right: .1em solid;
  white-space: nowrap;
  font-size: 1.2rem;
  height: 50%;
  width: 50%;
  animation:
          typing 2.5s steps(50, end) forwards,
          blink .8s infinite;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-body{
  /*background-color: #fbf7f2;*/
  background-color: var(--pg1-bg-color);
  opacity: 0.9;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--pg1-font-color);
  /*color: #262626;*/
}

.App-font-dark{
  color: var(--pg1-bg-color);
}

.App-font-light{
  color: var(--pg1-font-color);
}

.App-body-light{
  /*background-color: #fbf7f2;*/
  background-color: var(--pg1-font-color);
  opacity: 0.9;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--pg1-bg-color);
  /*color: #262626;*/
}

.App-body-light-full{
  /*background-color: #fbf7f2;*/
  background-color: var(--pg1-font-color);
  opacity: 0.9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--pg1-bg-color);
  /*color: #262626;*/
}

/*.App-container{*/
/*  scroll-snap-type: y mandatory;*/
/*}*/

.App-projects-streamlit{
  width: 100vh;
  height: 83vh;
  border-radius: 7px;
  padding-bottom: 1px;
  border-width: 1px;
  border-color: #272727;
}

.App-projects-google-sheets{
  width: 80%;
  height: 83vh;
  border-radius: 7px;
  padding-bottom: 1px;
  border-width: 1px;
  border-color: #272727;
}

.App-projects-marviva-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 25.25%; /* 16:9 Ratio */
  height: 0;
  overflow: hidden;
}

.App-projects-marviva {
  position: absolute;
  margin-top: -50px;
  width: 100%;
  max-width: calc(960px * 1.25);
  max-height: calc(541px * 1.25);
  height: 100%;
  border-radius: 5px;
  border-width: 2px;
  border-color: #272727;
  /*border-color: #fffefc;*/
  overflow: hidden;
  /*pointer-events: none;*/
}

.section{
  scroll-snap-align: start;
}

@media screen and (min-width: 0px) {
  .App-personal-text {
    /*transform: scale(0.9);*/
    position: absolute;
    font-size: 40px;
    top: 10.5vh;
    left: 2vh;
  }
}

@media screen and (min-width: 0px) {
  .App-personal-text-small{
    /*transform: scale(0.9);*/
    position: absolute;
    font-size: 18px;
    /*width: 45%;*/
    top: 22vh;
    left: 2vh;
  }
}

@media screen and (min-width: 0px) {
  .App-personal-text-smallest {
    /*transform: scale(0.9);*/
    position: absolute;
    font-size: 12px;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-text {
    /*transform: scale(1.11111111);*/
    position: absolute;
    font-size: 50px;
    top: 10.5vh;
    left: 8vh;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-text-small{
    /*transform: scale(1.11111111);*/
    position: absolute;
    font-size: 22px;
    /*width: 45%;*/
    top: 22vh;
    left: 8vh;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-text-smallest {
    position: absolute;
    font-size: 15px;
  }
}

@media screen and (min-width: 0px) {
  .App-personal-img {
    position: absolute;
    top: 12.5vh;
    right: 20vh;
    border-radius: 2px;
    height: 80vh;
    opacity: 0;
    transition: 1s ease;
  }
}

@media screen and (min-width: 1800px) {
  .App-personal-img {
    position: absolute;
    top: 12.5vh;
    right: 20vh;
    border-radius: 2px;
    height: 80vh;
    /*height: auto;*/
    /*width: auto\9;*/
    opacity: 1;
    transition: .5s ease;
  }
}

@media screen and (min-width: 0px) {
  .App-personal-follow-img{
    position: absolute;
    border-radius: 1.25px;
    max-width: 95%;
    opacity: 1;
    transition: .5s ease;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-follow-img{
    position: absolute;
    border-radius: 1.25px;
    max-height: 90%;
    opacity: 1;
    transition: .5s ease;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-follow-img div {
    position: absolute;
    /*bottom: 10;*/
    right: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    transition: visibility 0s, opacity 0.5s linear;
  }
}

/*.App-personal-img:hover {*/
/*  opacity: 0;*/
/*}*/


@media screen and (min-width: 1000px) {
  .App-personal-follow-img:hover {
    opacity: 1;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-follow-img:hover div {
    /*width: 150px;*/
    padding: 8px 15px;
    visibility: visible;
    opacity: 0.1;
  }
}

@media screen and (min-width: 1000px) {
  .App-personal-img-text {
    font-family: 'Times New Roman', serif;
    font-weight: bolder;
    font-size: 0px;
    color: orangered;
    /*color: white;*/
  }
}

.App-cv-body{
  border-style: double;
  border-width: 5px;
  border-radius: 10px;
  margin-top: 15px;
  padding: 5px;
  border-color: var(--pg1-font-color);
  background: var(--pg1-bg-color);
}

.App-cv-contact{
  font-size: 20px;
  margin-left: 40px;
  margin-top: -15px;
}

.App-cv-subject-date{
  display: flex;
  justify-content: space-between;
  margin-left: 35px;
  margin-right: 30px;
  margin-top: -48px;
}

.App-cv-content{
  font-size: 18px;
  margin-left: 35px;
}

.Subject{
  font-size: 18px;
}

.image-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Social-icons-gh{
  max-height: calc(var(--icon_sz) * 1.2);
  max-width: calc(var(--icon_sz) * 1.2);
  margin-bottom: calc(var(--icon_sz) * -0.125);
}

.Social-icons-ig{
  max-height: calc(var(--icon_sz) * 0.65);
  max-width: calc(var(--icon_sz) * 0.65);
  margin-left: calc(var(--icon_sz) * (-0.05 + var(--icon-space-between)));
  margin-bottom: calc(var(--icon_sz) * 0.2);
}

.Social-icons-sp{
  max-height: calc(var(--icon_sz) * 0.55);
  max-width: calc(var(--icon_sz) * 0.55);
  margin-bottom: calc(var(--icon_sz) * 0.315);
  margin-left: calc(var(--icon_sz) * (0.225 + var(--icon-space-between)));
}

.Social-icons-md{
  max-height: calc(var(--icon_sz) * 0.7);
  max-width: calc(var(--icon_sz) * 0.7);
  margin-bottom: calc(var(--icon_sz) * 0.15);
  margin-left: calc(var(--icon_sz) * (0.15 + var(--icon-space-between)));
}
